<template>
    <div>
        <Form ref="formValidate" :model="formValidate" :label-position="labelPosition" :rules="ruleValidate" :label-width="100">
        <FormItem label="联系手机" prop="tel">
            <Input v-model="formValidate.tel" placeholder="请输入手机号码" style="margin-left:-55px;"></Input>
        </FormItem>
        <FormItem label="您的姓名" prop="name">
            <Input v-model="formValidate.name" placeholder="请输入姓名" style="margin-left:-55px;"></Input>
        </FormItem>

        <FormItem label="意见建议" prop="content">
           <Input v-model="formValidate.content" style="margin-left:-55px;width:400px;" type="textarea" :autosize="{minRows: 10,maxRows: 10}" placeholder="请输入内容"></Input>
        </FormItem>

        <FormItem>
            <Button type="primary" style="width:90%;height:40px;margin-left:-30px;margin-top:20px;" @click="handleSubmit('formValidate')">立即注册</Button>
        </FormItem>
    </Form>
    </div>
</template>
<style>
    .ivu-btn{margin-left:45px;}
    .ivu-form-item-label{margin-top:10px;}
    .ivu-form-item-error-tip{margin-left:-55px;}
    .ivu-input {height:40px;}
</style>

<script>
import $ from 'jquery'

export default {
data () {
            return {
                labelPosition:'top',
                formValidate: {
                    tel:'',
                    name: '',
                    content:''
                },
                ruleValidate: {
                   name:[
                       {required: true,message: '请填写姓名',trigger: 'blur'}
                   ],
                    content: [
                        { required: true, message: '请输入内容', trigger: 'blur' }
                    ]
                   
                }
            }
},

methods: {
handleSubmit (name) {
  this.$refs[name].validate((valid) => {
    if (valid) {
      this.formValidate.token=this.$store.state.token;
      var param=JSON.stringify(this.formValidate);
      param = eval('(' + param + ')');
      //console.log(param.token);

      this.$axios({method:'post', url:window.configs.svcProt + "://" + window.configs.svcIpPort + '/submit_idea.html',params:param}).then(res=>{
          if(res.data.code=='000'){
              this.$Modal.success({
                  title: '温馨提示',
                  content: '提交成功，感谢您的反馈！'
              });
              $('#idea').hide();
          }
      });
                        
    } 
  })
}

}
}
</script>
