<template>
<div id="i-footer-box">
        <div id="i-footer-content">
            <div class="i-footer-lis left">
                    <dl>
                        <dt>关于我们</dt>
                        <a href="/#/about_us" title="公司简介"> <dd>公司简介</dd> </a>
                        <a href="/#/about_us" title="公司架构"> <dd>公司架构</dd> </a>
                        <a href="/#/about_us" title="企业荣誉"> <dd>企业荣誉</dd> </a>
                        <a href="/#/about_us" title="企业文化"> <dd>企业文化</dd> </a>
                        <a href="/#/about_us" title="企业资质"> <dd>企业资质</dd> </a>
                    </dl>
                </div>

                <div class="i-footer-lis left">
                    <dl>
                        <dt>新闻中心</dt>
                        <a href="/#/news?type=1" title="综合新闻"> <dd>综合新闻</dd> </a>
                        <a href="/#/news?type=1" title="一线风采"> <dd>一线风采</dd> </a>
                        <a href="/#/news?type=1" title="媒体聚焦"> <dd>媒体聚焦</dd> </a>
                        <a href="/#/news?type=1" title="内部动态"> <dd>内部动态</dd> </a>
                    </dl>
                </div>

                <div class="i-footer-lis left">
                    <dl>
                        <dt>业务领域</dt>
                        <router-link :to="{path:'/business_detail',query:{id:8}}"> <dd>基建领域</dd> </router-link>
                        <router-link :to="{path:'/business_detail',query:{id:10}}"> <dd>市政建设领域</dd> </router-link>
                        <router-link :to="{path:'/business_detail',query:{id:13}}"> <dd>变配电领域</dd> </router-link>
                        <router-link :to="{path:'/business_detail',query:{id:12}}"> <dd>环保领域</dd> </router-link>
                        <router-link :to="{path:'/business_detail',query:{id:11}}"> <dd>高低压供电领域</dd> </router-link>

                        <router-link :to="{path:'/business_detail',query: {id:'14'}}"> <dd>其他领域</dd> </router-link>
                    </dl>
                </div>

                <!--联系方式开始-->
                <div class="i-contact left">
                        <p>北京华创龙达建设工程有限公司</p>
                        <p>服务热线：400-030-1913</p>
                        <p>公司电话：010-53674313</p>
                        <p>企业邮箱：admin@huachuanglongda.com</p>
                        <p>公司地址：北京市朝阳区双桥康惠园3号院供电楼</p>
                </div>
               
                <div class="i-contact left">
                        <div class="i-service left">
                            <img src="~images/leftewm.png" width="100" height="100" />
                        </div>
                        <p id="i-erweima-title">扫码关注公众号</p>
                </div>

                <!--备案-->
                <p class="i-footer-keep">北京华创龙达建设工程有限公司 | <a href="https://beian.miit.gov.cn">京ICP备2021007797号</a></p>

            <!--内容结束-->

            <!--浮动图层开始-->
            <div class="i-footer-fixed absolute">
                <div class="i-footer-fixed-li left">
                    <div  class="i-footer-fixed-left left"> <img src="~images/zuanshi.png" width="80"/> </div>
                    <a href="/#/online_service" title="会员服务">
                    <div class="i-footer-fixed-right left">
                        <p>会员服务</p>
                        <p>全方位的优质服务体验</p>
                    </div>
                    </a>
                </div>

                <a href="/#/obtain_employment" title="就业指导">
                <div class="i-footer-fixed-li left">
                    <div  class="i-footer-fixed-left left">
                        <img src="~images/peixun.png" width="80"/>
                    </div>
                    <div class="i-footer-fixed-right left">
                        <p style="color:#fff">就业指导</p>
                        <p>拥有最专业的指导建议</p>
                    </div>
                </div>
                </a>

                <a href="/#/social_responsibility" title="社会责任">
                <div class="i-footer-fixed-li left">
                    <div  class="i-footer-fixed-left left">
                        <img src="~images/zeren.png" width="80"/>
                    </div>
                    <div class="i-footer-fixed-right left">
                        <p style="color:#fff;">社会责任</p>
                        <p>延续无限关爱</p>
                    </div>
                </div>
                </a>
            </div>
            <!--浮动图层结束-->

        </div>
</div>
</template>

<script>
export default {
name:"Footer",
data() {
  return {
  }
},
}
</script>

<style scoped>
    .i-footer-fixed-right p{font-size:20px;line-height:35px;text-align:left;font-weight:bold;padding-left:20px;color:#fff;}
    .i-footer-fixed-right p:first-child{color:#fff;}
    .i-footer-fixed-right{width:250px;height:70px;margin-top:50px;}
    .i-footer-fixed-left{width:70px;height:70px;margin-top:50px;margin-left:60px;}
    .i-footer-fixed-li{width:420px;height:170px;margin:20px;}
    .i-footer-fixed{width:1440px;height:210px;background:url('~images/footer_bj.jpg');top:-107px;z-index:100;border-radius:5px;}
    .i-footer-keep{width:100%;line-height:50px;border-top:1px solid #555;color:#999;font-size:15px;text-align:center;float:left;}
    #i-erweima-title{line-height:30px;text-align:center;}
    .i-service{width:100%;text-align:center;margin-top:20px;}
    .i-contact p{line-height:25px;margin-top:10px;}
    .i-contact{width:320px;height:200px;margin-top:160px;color:#ddd;margin-left:60px;}
    .i-footer-lis dl dd:hover{color:#999;}
    .i-footer-lis dl dd{font-size:15px;color:#ddd;line-height:25px;cursor:pointer;}
    .i-footer-lis dl dt{font-size:16px;color:#ddd;line-height:40px;font-weight:bold;}
    .i-footer-lis{width:210px;height:200px;text-align:center;margin-top:160px;}
    #i-footer-content{width:1440px;height:420px;margin:auto;}
    #i-footer-box{width:100%;height:420px;background:#333;margin-top:190px;position:relative;}
</style>
