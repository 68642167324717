<template>
<div>
    <div id="common-header">
        <div id="common-header-content">
            <div v-if="!userinfo">
                <label class="login" @click="register()">注册</label>
                <label class="login" @click="login();">登录</label>
            </div>
            <div v-if="userinfo">
                <label class="login">您好，{{userinfo.name}} <span id="loginOut" @click="loginOut">退出登录</span></label>
            </div>
            
            <label class="left common-header-text">华创龙达通过ISO-9001国际认证</label>
            <label class="right common-header-right">
            <!-- <label class="common-header-tel"><Icon style="font-size:18px;" type="md-phone-portrait" />手机端</label> -->
            <i class="el-icon-phone"> <font style="font-weight:500;">&nbsp;&nbsp;&nbsp;400-030-1913</font></i></label>
        </div>

        <div id="common-nav-logo-box">
            <div class="common-logo left"> <img src="~images/logo2.png" width="350"/> </div>
            <div class="common-menu right">
                <ul>
                    <router-link to="/index"> <li>首页</li> </router-link>
                    <router-link to="/about_us"> <li path="/about">关于我们</li> </router-link>
                    <router-link to="/news?type=1"> <li>新闻中心</li> </router-link>
                    <router-link to="/business_domain"> <li>业务领域</li> </router-link>
                    <router-link to="/party_building"> <li>党的建设</li> </router-link>
                    <router-link to="/social_responsibility"> <li>社会责任</li> </router-link>
                    <router-link to="/online_service"> <li path="/online_service">会员服务</li> </router-link>
                    <router-link to="/recruit"> <li path="/recruit">企业招聘</li> </router-link>
                    <router-link to="/obtain_employment"> <li path="/obtain">就业指导</li> </router-link>
                    <router-link to="/contact"> <li path="/contact">联系我们</li> </router-link>
                </ul>
            </div>
        </div>
    </div>

<contact-call></contact-call>

    <!--登录弹窗开始>
    <div class="alert-box" id="login">
        <div class="alert_content">
            <div class="alert_title left clearfix"><font style="font-size:16px;">用户登录</label></font> <label class="alert_colse" title="关闭"><Icon type="ios-close" @click="closeLogin()" /></label></div>
             <div class="reg_box left">
                <Login></Login>
                <p class="reg_text" @click="register">免费注册</p>
            </div>
        </div>
    </div-->

    <!--注册弹窗开始>
    <div class="alert-box" id="reg">
        <div class="alert_content" style="height:780px;">
            <div class="alert_title left clearfix"><font style="font-size:16px;">用户注册</label></font> <label title="关闭"><Icon type="ios-close" @click="closeReg()" /></label></div>
            <div class="reg_box left">
                <Register></Register>
            </div>
        </div>
    </div-->

    <!--意见反馈弹窗开始-->
    <div class="alert-box" id="idea">
        <div class="alert_content" style="height:780px;">
            <div class="alert_title left clearfix"><font style="font-size:16px;">意见反馈</label></font> <label title="关闭"><Icon type="ios-close" @click="closeIdea()" /></label></div>
            <div class="reg_box left">
                <Idea></Idea>
            </div>
        </div>
    </div>
    
    <Modal v-model="modal1" title="温馨提示" @on-ok="ok" @on-cancel="cancel"> <p>{{alertMsg}}</p> </Modal>
    
    </div>
   
</div>
</template>
<style>
    .reg_text{text-align:right;color:#00c296;padding-right:70px;cursor:pointer;}
    .ivu-btn-primary{width:55px;height:30px;margin-top:0px;}
    #loginOut{color:rgb(242,151,0);cursor:pointer;margin-left:10px;}
    .reg_box{width:100%;margin-top:30px;}
    #login,#reg,#idea{display:none;}
    .alert_title label{float:right;font-size:35px;margin-top:-10px;cursor:pointer;}
    .alert_title{line-height:50px;padding-left:20px;font-weight:bold;width:100%;border-bottom:1px solid #eee;}
    .alert_content{width:500px;height:450px;background:#fff;border:1px solid #ccc;border-radius:5px;margin:auto;margin-top:50px;}
    .alert-box{position:fixed;width:100%;height:100%;top:0px;left:0px;bottom:0px;right:0px;z-index:200;background:url('~images/default.png');}
    .login:hover{color:rgb(242,151,0);}
    .login{float:right;margin-left:10px;margin-right:10px;cursor:pointer;}
    .common-header-tel{font-size:14px;margin-right:20px;border-right:1px solid #eee;padding-right:10px;cursor:pointer;}
    .common-header-right{font-size:17px;font-weight:bold;}
    .common-menu ul{margin-left:30px;}
    .common-menu ul li{width:10%;float:left;line-height:30px;margin-top:20px;font-size:15px;text-align:center;}
    .common-menu ul li:hover{background:rgb(242,151,0);color:#fff;cursor:pointer;border-radius:5px;}
    .common-menu{width:1050px;height:90px;margin-left:40px;margin-top:30px;}
    .common-logo{width:350px;height:120px;}
    .common-logo img{margin-top:-7px;}
    #common-nav-logo-box{width:1440px;height:120px;margin:auto;}
    .common-header-text{margin-left:20px;font-size:15px;color:#666;}
    .el-icon-phone{color:rgb(242,151,0);margin-right:30px;}
    #common-header-content label{line-height:50px;}
    #common-header-content{width:1440px;height:50px;margin:auto;}
    #common-header{width:100%;height:50px;background:#f8f8f8;border-bottom:1px solid #efefef;}
</style>

<script>
import $ from 'jquery'
//import Register from './Register.vue'
//import Login from './Login.vue'
import ContactCall from '../index/ContactCall.vue'
import Idea from '../layout/Idea.vue'

export default {
  name: 'Header',
  data(){
      return {
        userinfo:eval('(' + this.$store.state.userinfo + ')'),
        alertMsg:'确定要退出登录吗?',
        modal1:false
      }
  },

  methods:{
      cancel() {
      },

      //关闭登录
      closeLogin(){
        $('#login').hide();
      },

      //关闭注册
      closeReg(){
        $('#reg').hide();
      },

      //关闭意见反馈
      closeIdea(){
        $('#idea').hide();
      },

      //登录弹窗
      login(){
          //$('#reg').hide();
          //$('#login').show();
          this.$router.push({path: '/login', query:{x: ""}});
      },

      //注册弹窗
      register(){
          //$('#reg').show();
          this.$router.push({path: '/register', query:{x: ""}});
      },

      loginOut(){
          this.modal1 = true;
      },

      ok(){
           sessionStorage.removeItem("userinfo");
           this.userinfo = false;
           //刷新当前页面
           window.location.reload();
      }
  },

  components:{
      ContactCall,
      //Register,
      //Login,
      Idea
  }
}
</script>


