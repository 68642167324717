<template>
<div class="side">
	<ul>
		<li class="shangqiao">
			<a href="javascript:;" @click="alertIdea" rel="nofollow"> <div><i class="bgs2"></i>意见反馈</div> </a>
		</li>

		<li class="sideewm"><i class="bgs3"></i>官方微信
			<div class="ewBox son"></div>
		</li>

		<li class="sideetel"><i class="bgs4"></i>联系电话
			<div class="telBox son">
				<dd class="bgs1"><span>座机</span>010-53674313</dd>
				<dd class="bgs2"><span>服务</span>400-030-1913</dd>
			</div>
		</li>
		
		<li class="sidetop" @click="goTop()"><i class="bgs6"></i>返回顶部</li>
	</ul>
</div>
</template>

<script src="~js/jquery-1.11.0.js" type="text/javascript" charset="utf-8"></script>
<script>
import $ from 'jquery'

export default {
name:"",
data() {
  return {
  }
},

methods:{
  goTop() {
		$('html,body').animate({
			scrollTop: 0
		}, 500)
	},

	alertIdea(){
		$('#idea').show();
	}
}
}
</script>

<style scoped>

</style>
